
import Vue from "vue"
export default Vue.extend({
	name: "ClientInfo",
	methods: {
		goToRosberg(): void {
			window.open("https://www.verji.no")
		},
	},
})
